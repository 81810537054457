<template>
  <main class="kb-main" id="kb-educourse">
    <lxp-main-header
        :show-title="true"
        :show-breadcrumb="true"
        title="글로벌연수신청">
      <template v-slot:inner>
        <div class="header-column">
          <div class="download">
            <file-down-link file-key="TrainGlobalGuide" />
<!--            <file-down-link :file-key="mngrFileKey" />-->
          </div>
        </div>
      </template>
    </lxp-main-header>
    <div class="main-content main-component">
      <div class="educourse-list-container">
        <!-- list-top -->
        <article class="content-section section-spacing">

          <header class="section-header">
            <h4 class="title">신청현황</h4>
          </header>
          <div class="kb-table kb-table-bordered-v2">
            <table>
              <colgroup>
                <col style="width:auto;">
<!--              <col style="width:300px">-->
                <col style="width:280px;">
                <col style="width:280px;">
                <col style="width:280px;">
              </colgroup>
              <thead>
              <tr>
                <th colspan="2"><span class="th-title">신청회차</span></th>

<!--                <th><span class="th-title">부점장승인여부</span></th>-->
                <th><span class="th-title">선정여부</span></th>
                <th><span class="th-title">대기여부</span></th>
              </tr>
              </thead>
              <tbody>
              <template v-for="(item, idx) in crseDistList" :key="idx">
              <tr>
                <td>
                  <template v-if="(crseDistList.length>0 && (item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY   ||
                                item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) &&
                                item.takeSttDcd !== crseAplyDcds.TAKE_STT_REJECT)">
                   <strong class="td-text" v-html="item.crseNm"></strong><br>
                    <strong class="td-text">{{ timestampToDateFormat(item.bgngDt, 'yyyy.MM.dd') }} ~
                      {{ timestampToDateFormat(item.endDt, 'yyyy.MM.dd') }}</strong>
                  </template>
                  <template v-else>
                    -
                  </template></td>
                <td>
                  <template v-if="(crseDistList.length>0 && (item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_APPLY   ||
                                item.regCrseAplySttDcd === crseAplyDcds.REG_CRSE_APLY_STT_CHANGE) &&
                                item.takeSttDcd !== crseAplyDcds.TAKE_STT_REJECT)">
                    <button v-if="crseDistList.length>0 && item.takeSttDcd !== '2050002' && item.aplyEndDt >= new Date().getTime()"
                            class="kb-btn kb-btn-outline rounded-lg" type="button" @click="showEditModal(item)">
                      <span class="text">불참(취소)</span>
                    </button>
                  </template>
                  <template v-else>
                    -
                  </template></td>
                <td>
                 <template v-for="(drawItem, idx) in drawStatus" :key="idx">
                   <template v-if="item.distCrseSn == drawItem.distCrseSn ">
                     <template v-if = "drawItem.winStatusYn === 'Y'">
                       <div class="animate__animated animate__fadeInRight"  style=" display:flex; align-items: center; justify-content: center;">
                       <img src="@/assets/lxp/images/prep/travel_1.png" alt="" style="width:80px; height:80px;">
                       <strong class="td-text text-success" >선정</strong>
                       </div>
                     </template>
                     <template v-else>
                       <div style=" display:flex; align-items: center; justify-content: center;">
                       <strong class="td-text text-danger" >미선정</strong>
                       </div>
                     </template>
                   </template>
                </template>
                </td>
                <td>
                  <template v-for="(drawItem, idx) in drawStatus" :key="idx">
                  <template v-if="item.distCrseSn == drawItem.distCrseSn ">
                    <template v-if = "drawItem.waitStatusYn === 'Y'">
                      <strong class="td-text text-primary" >대기( 순번: {{drawItem.waitRankSn}} )</strong>
                    </template>
                      <template v-else>
                        <strong class="td-text" >-</strong>
                      </template>
                    </template>
                  </template>
                </td>

              </tr>
              </template>
              </tbody>
            </table>
          </div>
          <div class="d-flex justify-content-end mt-2 text-muted">
            ※ 대상자 선정 완료 후 별도 확인 안내 예정입니다.
          </div>
        </article>



        <div class="top-column">&nbsp;</div>

        <template v-if="aplyItems.length > 0">
          <div class="educourse">
            <div class="educourse-summary course-row" style="border-top: none">
              <article class="course-card">
                <div class="course-content" style="min-height: 0;">
                  <div class="content-header" style="margin-top: 16px; margin-bottom: 0;">
                    <h4 class="title">
                     신청과정목록
                    </h4>
                  </div>
                  <div class="course-total-count">
                    <div class="kb-form-search">
                      <div class="kb-form-search-status">
                        <span class="text">총 {{ aplyItems.length }}건</span>
                      </div>
                    </div>
                  </div>
                </div>
              </article>
            </div>
          </div>
          <ul class="educourse-list">
            <li v-for="(item, index) in aplyItems" :key="index" class="list-item">
              <div class="educourse">
                <div class="educourse-summary course-row" style="padding-bottom: 10px;cursor: pointer"
                     @click="collapseOrExpand(index)">
                  <article class="course-card">
                    <div class="course-image">
                      <figure class="image-figure">
                        <img v-if="item.mstThumb" :src="getThumbUrl(item.mstThumb)" alt="">
                        <CourseThumb
                            v-else
                            :num="item.crseMstSn"
                        />
                      </figure>
                    </div>
                    <div class="course-content">
                      <div class="content-header">
                        <h5 class="title">
                          {{ item.crseMstNm }}
                        </h5>
                      </div>
                      <div :class="{'content-footer': isMobile}">
                        <div class="content-meta">
                          <span class="text" :class="{'txt_line' : !isMobile, 'txt_multi' : isMobile}" style="color:var(--kb-gold)">{{ item.crseSumup }}</span>
                        </div>
                        <div class="content-meta" style="margin-top: 20px">
                          <span class="text">
                            {{ item.orgnzNm }}
                            {{ item.mstTotLrnPerid ? ' | '.concat(item.mstTotLrnPerid,'일') : '' }}
                            {{ item.crseMstMlg ? ' | 마일리지'.concat(item.crseMstMlg) : '' }}
                          </span>
                        </div>
                        <div v-if="isMobile" class="striped-list-wrap">
                          <ul class="striped-list">
                            <li class="striped-item">
                              <div class="kb-collapse" :class="{ 'is-active' : !showDists[index] }">
                                <div class="kb-collapse-toggle striped-row" style="background-color: white"
                                     @click="collapseOrExpand(index)">
                                  <div class="striped-column column-arrow" style="padding: 0px 0px;min-height: 0px"><i class="icon-arrow"></i></div>
                                </div>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <div class="course-actions" style="display: flex;justify-content: flex-end;flex-direction: column;margin-left: 10px">
                      <div></div>
                      <div v-if="!isMobile" class="striped-list-wrap">
                        <ul class="striped-list">
                          <li class="striped-item">
                            <div class="kb-collapse" :class="{ 'is-active' : !showDists[index] }">
                              <div class="kb-collapse-toggle striped-row" :style="showDists[index] ? {'background-color':'lightgray'} : {}">
                                <div class="striped-column column-arrow"><i class="icon-arrow"></i></div>
                              </div>
                            </div>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </article>
                </div>

                <template v-if="showDists[index]">
                  <div v-if="item.crseDists.length > 0" class="educourse-content">
                    <div class="kb-table kb-table-round">
                      <table>
                        <colgroup>
                          <col style="width:auto;">
                          <col style="width:280px;">
                          <col style="width:320px;">
                          <col style="width:160px;">
                          <col style="width:140px;">
                        </colgroup>
                        <thead>
                        <tr>
                          <th><span class="th-title">과정차수명</span></th>
                          <th><span class="th-title">연수기간</span></th>
                          <th><span class="th-title">신청기간</span></th>
                          <th><span class="th-title">신청인원수</span></th>
                          <th><span class="th-title"> </span></th>
                        </tr>
                        </thead>
                        <tbody>
                        <template v-for="(dist, idx) in item.crseDists" :key="idx">
                          <tr v-if="isShowApply(dist)" style="cursor: pointer" @click="showApplyView(dist.distCrseSn)">
                            <td><strong class="td-text">{{ dist.crseNm }}</strong></td>
                            <td><strong class="td-text">{{ timestampToDateFormat(dist.bgngDt, 'yyyy.MM.dd') }} ~
                              {{ timestampToDateFormat(dist.endDt, 'yyyy.MM.dd') }}</strong></td>
                            <td>
                              <strong v-if="dist.aplyBgngDt" class="td-text">{{
                                timestampToDateFormat(dist.aplyBgngDt, 'yyyy.MM.dd')
                                }} ~ {{ timestampToDateFormat(dist.aplyEndDt, 'yyyy.MM.dd hh:mm') }}</strong>
                              <strong v-else class="td-text">-</strong>
                            </td>
                            <td><strong class="td-text">{{
                              dist.aplyRegCnt > 0 ?
                              `${numberComma(dist.aplyRegCnt)}명` :
                              '-'
                              }}</strong></td>
                            <td class="text-end">
                              <router-link :to="{name: 'ApplyTrainView', params: {distCrseSn: dist.distCrseSn}}"
                                           class="td-link">
                                <template v-if="limitOver(dist)">
                                  <strong class="td-text" style="color: red">정원초과</strong>
                                </template>
                                <template v-else>
                                  <strong class="td-text">상세</strong><i class="icon-arrow"></i>
                                </template>
                              </router-link>
                            </td>
                          </tr>
                        </template>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </template>
              </div>
            </li>
          </ul>
        </template>

      </div>
    </div>
    <!-- //main-content -->

  </main>
  <ApplyStatusEditModal
      v-if="edit.toggle && !isMobile"
      v-model="edit.toggle"
      :target="edit.target"
      :dtlItem="dtlItem"
      @completed="getCourseRegList"
  />
</template>

<script>

import LxpMainHeader from '@/components/common/LxpMainHeader';
import CourseThumb from '@/components/apply/CourseThumb';
import FileDownLink from "@/components/common/FileDownLink";
import {trainGlobalSetup} from '@/assets/js/modules/train/train-global-setup';
import ApplyStatusEditModal from "@/components/apply/ApplyStatusEditModal";

export default {
  name: 'TrainGlobal',
  components: {LxpMainHeader, CourseThumb,
    FileDownLink,
    ApplyStatusEditModal,},
  setup: trainGlobalSetup
};
</script>

<style scoped>

.lxp-layout .course-row .course-total-count {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.kb-table-image img {
  height: 60px;
  width: 60px;
  border-radius: 16px;
}

</style>